import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import {
  Header,
  DialogMessage,
  Icon,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import { IconButton } from "@tvg/design-system/web";
import { EVENTS } from "@urp/amplitude/src/modules/talentPicks/constants";
import TalentPicksCard from "./components/TalentPicksCard";
import CardsLoading from "./components/CardsLoading";
import {
  TalentPicksContainer,
  StyledHeader,
  ArrowWrapper,
  FiltersWrapper,
  FiltersContainer,
  ContentContainer,
  HeaderContainer,
  CardsContainer
} from "./styled-components";
import useTalentPicksWithFilters from "./hooks/useTalentPicksWithFilters";
import { getTalentPicksPageMsgs } from "./selectors";
import TalentsFilterSelector from "./components/TalentsFilterSelector";
import PicksFilterSelector from "./components/PicksFilterSelector";
import { triggerAmplitudeEvent } from "./utils/amplitude";

const TalentPicks = ({ qaLabel = "talentPicks" }: { qaLabel?: string }) => {
  const initialRender = useRef(true);
  const talentPicksPageMsgs = useSelector(getTalentPicksPageMsgs);
  const {
    loading,
    trackOptions,
    trackFilter,
    expertFilter,
    talentPicksActive,
    talentPicksResulted,
    expertOptions,
    onTrackFilterHandler,
    onExpertFilterHandler,
    onExpertsFilterHandler
  } = useTalentPicksWithFilters();

  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const hasPicksActive = talentPicksActive.length > 0;
  const hasPicksResulted = talentPicksResulted.length > 0;

  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !hasPicksActive) {
      if (!initialRender.current) {
        triggerAmplitudeEvent(EVENTS.EMPTY_STATE_VIEWED);
      } else {
        initialRender.current = false;
      }
    }
  }, [loading, hasPicksActive]);

  return (
    <TalentPicksContainer data-qa-label={qaLabel}>
      <StyledHeader shouldHide={false}>
        <ArrowWrapper>
          <IconButton
            qaLabel=""
            iconName="arrowLeft"
            onClick={() => navigate(-1)}
            mode="dark"
            overrideColors={{
              default:
                "--fd-colors-component-button-transparent-background-base",
              hover:
                "--fd-colors-component-button-transparent-background-hover",
              active:
                "--fd-colors-component-button-transparent-background-hover"
            }}
          />
        </ArrowWrapper>
        <Header
          tag="h1"
          padding="space-7"
          fontFamily="bold"
          qaLabel={`${qaLabel}-header`}
        >
          {talentPicksPageMsgs?.title}
        </Header>
      </StyledHeader>
      <FiltersWrapper>
        <FiltersContainer
          shouldHide={
            (!hasPicksActive &&
              !hasPicksResulted &&
              !trackFilter.value &&
              !expertFilter.value) ||
            (expertOptions?.length === 1 && trackOptions.length === 1)
          }
          isLoading={loading}
        >
          {isMobile ? (
            <TalentsFilterSelector
              loading={loading}
              talents={expertOptions}
              onSelect={onExpertsFilterHandler}
            />
          ) : (
            <PicksFilterSelector
              loading={loading}
              trackOptions={trackOptions}
              expertOptions={expertOptions}
              trackFilter={trackFilter}
              expertFilter={expertFilter}
              onTrackFilterHandler={onTrackFilterHandler}
              onExpertFilterHandler={onExpertFilterHandler}
            />
          )}
        </FiltersContainer>
      </FiltersWrapper>

      <ContentContainer>
        {loading ? (
          <CardsLoading />
        ) : (
          <>
            {hasPicksActive ? (
              <CardsContainer data-qa-label={`${qaLabel}-cards-active`}>
                {talentPicksActive.map((talentPick, i) => (
                  <TalentPicksCard
                    key={talentPick.id}
                    cardPosition={i}
                    talentPick={talentPick}
                    showAddToBetslipButton={
                      talentPick.raceStatus !== RaceStatusEnum.RACE_OFF
                    }
                    qaLabel={`${qaLabel}-card-active-${talentPick.id}`}
                    isFDTVPage
                  />
                ))}
              </CardsContainer>
            ) : (
              <DialogMessage
                qaLabel={`${qaLabel}-empty-message`}
                title={talentPicksPageMsgs?.emptyState.title}
                description={talentPicksPageMsgs?.emptyState.description}
                marginTop="42px"
                padding="space-8"
                variant="info"
                marginX="auto"
              />
            )}

            {hasPicksResulted ? (
              <>
                <HeaderContainer
                  data-qa-label={`${qaLabel}-resulted-header-container`}
                >
                  <Icon
                    name="accountHistory"
                    size="l"
                    lineColor="grey.900"
                    marginRight="space-2"
                    qaLabel={`${qaLabel}-resulted-icon`}
                  />
                  <Header
                    tag="h2"
                    fontFamily="medium"
                    color="grey.900"
                    qaLabel={`${qaLabel}-resulted-header`}
                  >
                    {talentPicksPageMsgs?.picksResulted}
                  </Header>
                </HeaderContainer>
                <CardsContainer data-qa-label={`${qaLabel}-cards-resulted`}>
                  {talentPicksResulted.map((talentPick, i) => (
                    <TalentPicksCard
                      key={talentPick.id}
                      cardPosition={i}
                      talentPick={talentPick}
                      showAddToBetslipButton={false}
                      qaLabel={`${qaLabel}-card-resulted-${talentPick.id}`}
                      isFDTVPage
                    />
                  ))}
                </CardsContainer>
              </>
            ) : null}
          </>
        )}
      </ContentContainer>
    </TalentPicksContainer>
  );
};

export default TalentPicks;
