import React, { useMemo, useRef } from "react";

import { Sort, Option, AnimatedDropdown } from "@tvg/design-system";
import { PickFilterProps } from "../types";
import { CONTENT_FILTER_WIDTH } from "../constants";

const PickFilter = ({
  filterLabel,
  selectedValue,
  selectedLabel,
  filterOptions,
  onFilterChangeHandler,
  qaLabel
}: PickFilterProps) => {
  const filterRef = useRef<HTMLElement>(null);

  const filterContentWidth = useMemo(() => {
    const filterClientWidth = filterRef?.current?.clientWidth;
    return filterClientWidth && filterClientWidth > CONTENT_FILTER_WIDTH
      ? filterClientWidth
      : CONTENT_FILTER_WIDTH;
  }, [filterRef?.current?.clientWidth]);

  return (
    <Sort
      buttonLabel={filterLabel}
      selectedValue={selectedValue}
      selectedLabel={selectedLabel}
      onChange={onFilterChangeHandler}
      isDisabled={filterOptions.length <= 2}
      isContentCustom
      // @ts-ignore
      ref={filterRef}
      qaLabel={qaLabel}
    >
      <AnimatedDropdown
        qaLabel={`${qaLabel}-dropdown`}
        paddingTop="space-4"
        width={`${filterContentWidth}px`}
      >
        {filterOptions.map((option) => (
          <Option
            key={option.value}
            label={option.label}
            value={option.value}
            qaLabel={`${qaLabel}-${option.value}`}
            ellipsizeMode="tail"
            numberOfLines={1}
          />
        ))}
      </AnimatedDropdown>
    </Sort>
  );
};

export default PickFilter;
