import React, { FC } from "react";
import { useSelector } from "react-redux";
import { getTalentPicksPageMsgs } from "../../selectors";
import PickFilter from "../pickFilter";
import FiltersLoading from "../FiltersLoading";
import { FilterOption, TalentFilter } from "../../types";

interface PicksFilterSelectorProps {
  qaLabel?: string;
  loading: boolean;
  trackOptions: FilterOption[];
  expertOptions: TalentFilter[];
  trackFilter: FilterOption;
  expertFilter: FilterOption;
  onTrackFilterHandler: (selectValue: string) => void;
  onExpertFilterHandler: (selectValue: string) => void;
}

const PicksFilterSelector: FC<PicksFilterSelectorProps> = ({
  qaLabel = "talentPicks",
  loading,
  trackOptions,
  expertOptions,
  trackFilter,
  expertFilter,
  onTrackFilterHandler,
  onExpertFilterHandler
}) => {
  const talentPicksPageMsgs = useSelector(getTalentPicksPageMsgs);

  return (
    <>
      {loading ? (
        <FiltersLoading />
      ) : (
        <>
          <PickFilter
            filterLabel={talentPicksPageMsgs?.trackFilter?.filterLabel}
            selectedValue={trackFilter.value}
            selectedLabel={trackFilter.label}
            filterOptions={trackOptions}
            onFilterChangeHandler={onTrackFilterHandler}
            qaLabel={`${qaLabel}-track-filter`}
          />
          <PickFilter
            filterLabel={talentPicksPageMsgs?.expertFilter?.filterLabel}
            selectedValue={expertFilter.value}
            selectedLabel={expertFilter.label}
            filterOptions={expertOptions}
            onFilterChangeHandler={onExpertFilterHandler}
            qaLabel={`${qaLabel}-expert-filter`}
          />
        </>
      )}
    </>
  );
};

export default PicksFilterSelector;
